import React, { PureComponent } from "react";

import ApproachImg from "../../../img/approach.png";

import styles from "../Home.module.scss";

export default class Approach extends PureComponent {
  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div>
            <img src={ApproachImg} />
          </div>
          <div className={styles.SplitContentText}>
            <div>
              <div className={styles.ApproachContentDiv}>
                <h3 style={{ margin: 0, lineHeight: 1 }}>
                  Powerful branding brings
                </h3>
                <h3 style={{ marginBottom: "20px", lineHeight: 1 }}>
                  your deeper purpose into light.
                </h3>
                <p>
                  Casi Ari is a brand and design agency focused on creating
                  evocative identity systems for visionary businesses and
                  organizations. As guides, our role is to translate your vision
                  with a mind towards brand growth and evolution.
                </p>
              </div>
            </div>
            <div>
              <a
                onClick={() => (window.location.hash = "services")}
                className={styles.NextPageButton}
              >
                See our services
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
