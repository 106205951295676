import React, { PureComponent } from "react";

import ContactImg from "../../../../img/contact.jpg";

import styles from "../../Home.module.scss";
import formStyles from "../../../Forms/Form.module.scss";

export default class Contact extends PureComponent {
  resetValidation = e => {
    e.target.setCustomValidity("");
  };

  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div style={{ flexGrow: 1 }}>
            <img src={ContactImg} />
            <div className={styles.BackgroundOverlay} />
          </div>
          <div className={styles.ContactContent}>
            <div style={{ marginTop: "20px" }}>
              <div style={{ maxWidth: "250px" }}>
                <h3 style={{ margin: 0, whiteSpace: "nowrap" }}>
                  Your journey
                </h3>
                <h3 style={{ margin: 0, whiteSpace: "nowrap" }}>
                  begins here.
                </h3>
                <br />
                <div>
                  <p>
                    We are so excited to serve you and your brand. Please use
                    this form, or give us a call at (682) 808-9902 to discuss
                    your vision further.
                  </p>
                  <div>
                    <p>
                      Office hours:
                      <br />
                      Monday - Friday
                      <br />
                      9:00 a.m. to 6:00 p.m.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <form
                name="project"
                className={formStyles.Form}
                method="POST"
                netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify="true"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="project" />
                <div className={formStyles.FormFieldsWrapper}>
                  <input
                    required
                    onInvalid={e =>
                      e.target.setCustomValidity("Please enter your name.")
                    }
                    onInput={this.resetValidation}
                    name="name"
                    placeholder="My name is:"
                  />
                  <input
                    // required
                    // onInvalid={e =>
                    //   e.target.setCustomValidity(
                    //     "Please enter a business or organization."
                    //   )
                    // }
                    // onInput={this.resetValidation}
                    name="business"
                    placeholder="My organization is:"
                  />
                </div>
                <div className={formStyles.FormFieldsWrapper}>
                  <input
                    required
                    onInvalid={e =>
                      e.target.setCustomValidity("Please enter a valid email.")
                    }
                    onInput={this.resetValidation}
                    name="email"
                    placeholder="My email is:"
                    type="email"
                  />
                  <input
                    // required
                    // onInvalid={e =>
                    //   e.target.setCustomValidity(
                    //     "Please enter a phone number in the format 123-456-7890."
                    //   )
                    // }
                    // onInput={this.resetValidation}
                    name="phone"
                    placeholder="My phone number is:"
                    type="tel"
                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  />
                </div>
                <div className={formStyles.FormFieldsWrapper}>
                  <input
                    // required
                    // onInvalid={e =>
                    //   e.target.setCustomValidity(
                    //     "Please enter what you're passion about."
                    //   )
                    // }
                    // onInput={this.resetValidation}
                    name="passion"
                    placeholder="We're passionate about:"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className={formStyles.FormFieldsWrapper}>
                  <textarea
                    // required
                    // onInvalid={e =>
                    //   e.target.setCustomValidity("Please enter the details.")
                    // }
                    // onInput={this.resetValidation}
                    name="details"
                    rows={3}
                    placeholder="Here's the details:"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className={formStyles.FormFooter}>
                  <div>
                    <h2 className={formStyles.MobileHeadline}>
                      Your journey begins here.
                    </h2>
                  </div>
                  <button type="submit">BEGIN</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
