import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Home from "../components/Home";

export default class IndexPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Home" />
        <Home />
      </Fragment>
    );
  }
}
