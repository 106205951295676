import React, { PureComponent } from "react";
import cx from "classnames";

import HomeVideo from "../../../img/home.mp4";
import HomePoster from "../../../img/home.jpg";

import styles from "../Home.module.scss";

export default class Approach extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      hasPlayed: false,
      hasWaited: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
      this.playVideo();
    }, 1000);

    setTimeout(() => {
      this.setState({
        hasWaited: true
      });
    }, 200);
  }

  playVideo = () => {
    const video = document.getElementById("home-video");
    if (video) {
      video.muted = true;
      video.play();
    }
  };

  playStarted = () => {
    this.setState({ hasPlayed: true });
  };

  render() {
    const { loaded, hasPlayed, hasWaited } = this.state;
    return (
      <div>
        <div
          className={cx(styles.BackgroundContainer, {
            [styles.FadeIn]: loaded
          })}
        >
          <video
            id="home-video"
            muted
            loop
            autoPlay
            playsInline
            onPlay={this.playStarted}
            poster={!hasPlayed && hasWaited ? HomePoster : null}
            className={cx({
              [styles.Poster]: !hasPlayed,
              [styles.PostedFade]: !hasPlayed && hasWaited
            })}
          >
            <source src={HomeVideo} type="video/mp4" />
          </video>
          <div className={styles.BackgroundOverlay} />
          <div className={styles.Content}>
            <div className={styles.LeftText}>
              <h1>Where will great</h1>
              <h1>design take you?</h1>
            </div>
            <a
              onClick={() => (window.location.hash = "approach")}
              className={cx(styles.NextPageButton, styles.Main)}
            >
              Find out
            </a>
          </div>
          {/* <div
            className={cx(styles.PlayButton, {
              [styles.FadeIn]: !hasPlayed && hasWaited
            })}
          >
            <FaPlay onClick={this.playVideo} />
          </div> */}
        </div>
      </div>
    );
  }
}
