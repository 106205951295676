import React, { PureComponent } from "react";
import { throttle } from "lodash";
import cx from "classnames";

import Navbar from "../Navbar";
import PageBar from "../PageBar";
import Footer from "../Footer";

import Landing from "./Screens/Landing";
import Approach from "./Screens/Approach";
import Services from "./Screens/Services";
import Work from "./Screens/Work";
import Contact from "./Screens/Contact";

import styles from "./Home.module.scss";

export default class Home extends PureComponent {
  constructor(props) {
    super(props);

    this.throttledWheelFn = throttle(this.onWheel, 2000, {
      leading: true,
      trailing: false
    });

    this.state = {
      page: 0,
      loaded: false,
      showNextButton: false
    };

    this.xDown = null;
    this.yDown = null;
  }

  componentDidMount() {
    const loaderElem = document.getElementById("loader");

    let timeout = 2000;
    // if they have already been to some other page, and is navigating "non-fresh",
    // don't wait so long for the fade/load
    if (!loaderElem) {
      timeout = 500;
    }

    setTimeout(() => {
      this.setState({
        loaded: true
      });
      const loaderElem = document.getElementById("loader");
      if (loaderElem) {
        loaderElem.parentNode.removeChild(loaderElem);
      }
    }, timeout);

    window.addEventListener("hashchange", this.updatePageByHash);
    this.updatePageByHash(20);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.updatePageByHash);
  }

  updatePageByHash = something => {
    const trimmedHash = window.location.hash.replace(/^\/|\/$/g, "");
    switch (trimmedHash) {
      case "#approach":
        this.setState({ page: 1, showNextButton: false });
        break;
      case "#services":
        this.setState({ page: 2, showNextButton: false });
        break;
      case "#work":
        this.setState({ page: 3, showNextButton: false });
        break;
      case "#contact":
        this.setState({ page: 4, showNextButton: false });
        break;
      default:
        this.setState({ page: 0, showNextButton: false });
        break;
    }
    if (something === 20) {
      setTimeout(() => {
        this.setState({ showNextButton: true });
      }, 2500);
    } else {
      setTimeout(() => {
        this.setState({ showNextButton: true });
      }, 500);
    }
  };

  onWheel = e => {
    const { page } = this.state;
    const deltaY = e.deltaY;

    switch (page) {
      case 0:
        if (deltaY > 0) {
          window.location.hash = "approach";
        } else if (deltaY < 0) {
          window.location.hash = "contact";
        }
        break;
      case 1:
        if (deltaY > 0) {
          window.location.hash = "services";
        } else if (deltaY < 0) {
          window.location.hash = "";
        }
        break;
      case 2:
        if (deltaY > 0) {
          window.location.hash = "work";
        } else if (deltaY < 0) {
          window.location.hash = "approach";
        }
        break;
      case 3:
        if (deltaY > 0) {
          window.location.hash = "contact";
        } else if (deltaY < 0) {
          window.location.hash = "services";
        }
        break;
      case 4:
        if (deltaY > 0) {
          window.location.hash = "";
        } else if (deltaY < 0) {
          window.location.hash = "work";
        }
        break;
    }
  };

  getTouches = evt => {
    return evt.touches || evt.originalEvent.touches;
  };

  handleTouchStart = evt => {
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  };

  handleTouchMove = evt => {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;
    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
    } else {
      const { page } = this.state;

      switch (page) {
        case 0:
          if (yDiff > 0) {
            window.location.hash = "approach";
          } else {
            window.location.hash = "contact";
          }
          break;
        case 1:
          if (yDiff > 0) {
            window.location.hash = "services";
          } else {
            window.location.hash = "";
          }
          break;
        case 2:
          if (yDiff > 0) {
            window.location.hash = "work";
          } else {
            window.location.hash = "approach";
          }
          break;
        case 3:
          if (yDiff > 0) {
            window.location.hash = "contact";
          } else {
            window.location.hash = "services";
          }
          break;
        case 4:
          if (yDiff > 0) {
            window.location.hash = "";
          } else {
            window.location.hash = "work";
          }
          break;
      }
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  };

  getPathName = () => {
    const { page } = this.state;
    switch (page) {
      case 0:
        return "";
      case 1:
        return "approach";
      case 2:
        return "services";
      case 3:
        return "work";
      case 4:
        return "contact";
      default:
        return "";
    }
  };

  goTo = page => {
    window.location.hash = page;
  };

  render() {
    const { loaded, showNextButton, page } = this.state;
    return (
      <div
        onWheel={this.throttledWheelFn}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        className={cx(styles.Home, {
          [styles.Loaded]: loaded,
          [styles.NextBtnVisible]: showNextButton
        })}
      >
        <Navbar goTo={this.goTo} pathname={this.getPathName()} />
        <div
          className={cx(styles.Page, { [styles.Active]: page === 0 && loaded })}
        >
          <Landing />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 1 && loaded })}
        >
          <Approach />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 2 && loaded })}
        >
          <Services />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 3 && loaded })}
        >
          <Work isActive={page === 3} />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 4 && loaded })}
        >
          <Contact />
        </div>
        <PageBar pathname={this.getPathName()} />
        <Footer goTo={this.goTo} />
      </div>
    );
  }
}
