import React, { PureComponent } from "react";
import cx from "classnames";

import styles from "./PageBar.module.scss";

export default class PageBar extends PureComponent {
  render() {
    const { pathname } = this.props;
    const path = pathname.replace(/^\/|\/$/g, "");
    return (
      <div className={styles.PageBar}>
        <div>
          <a
            onClick={() => (window.location.hash = "")}
            className={cx({
              [styles.Active]: path === ""
            })}
          />
          <a
            onClick={() => (window.location.hash = "approach")}
            className={cx({
              [styles.Active]: path === "approach"
            })}
          />
          <a
            onClick={() => (window.location.hash = "services")}
            className={cx({
              [styles.Active]: path === "services"
            })}
          />
          <a
            onClick={() => (window.location.hash = "work")}
            className={cx({
              [styles.Active]: path === "work"
            })}
          />
          <a
            onClick={() => (window.location.hash = "contact")}
            className={cx({
              [styles.Active]: path === "contact"
            })}
          />
        </div>
      </div>
    );
  }
}
