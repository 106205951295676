import React, { PureComponent } from "react";

import ServicesImg from "../../../img/services.jpg";

import styles from "../Home.module.scss";

export default class Services extends PureComponent {
  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div>
            <img src={ServicesImg} />
            <div className={styles.BackgroundOverlay} />
          </div>
          <div className={styles.SplitContentText}>
            <div>
              <div className={styles.ContentBlocks}>
                <div>
                  <h4>Brand Strategy</h4>
                  <ul>
                    <li>Brand Positioning</li>
                    <li>Market Analysis</li>
                    <li>Brand Purpose</li>
                  </ul>
                </div>
                <div>
                  <h4>Visual Identity</h4>
                  <ul>
                    <li>Brand Identity</li>
                    <li>Brand Assets</li>
                    <li>UI + Web Design</li>
                    <li>Social Media</li>
                    <li>Marketing Collateral</li>
                  </ul>
                </div>
                <div>
                  <h4>Brand Voice</h4>
                  <ul>
                    <li>Naming</li>
                    <li>Messaging</li>
                    <li>Communications</li>
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <a
                onClick={() => (window.location.hash = "work")}
                className={styles.NextPageButton}
              >
                See our work
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
