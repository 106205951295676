import React, { PureComponent } from "react";
import cx from "classnames";
import { Link } from "gatsby";

import styles from "./Work.module.scss";

import TouchScroll from "./touchscroll";

export default class Work extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loaded: true });
    }, 3000);

    const viewer = new TouchScroll();
    viewer.init({
      id: "work-cards",
      draggable: true,
      wait: false
    });
  }

  render() {
    const { loaded } = this.state;
    const { isActive } = this.props;
    return (
      <div
        className={cx(styles.WorkParent, {
          [styles.Loaded]: loaded && isActive
        })}
      >
        <div className={styles.Work}>
          <div>
            <div className={styles.Description}>
              <h3 style={{ lineHeight: 1, marginBottom: "20px" }}>
                Our purpose is to help you design yours.
              </h3>
              <p style={{ color: "grey" }}>
                Our clients continue to serve as our inspiration. By diving into
                the essence of each brand, we create a space for it to emerge
                into its own form, serving the world as it always was intended
                to.
              </p>
              <h5
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.hash = "contact";
                }}
              >
                Start your journey today.
              </h5>
            </div>
            <div
              className={styles.Cards}
              id="work-cards"
              onScroll={this.cardScroll}
            >
              <div>
                <Link to="/kiragrace">Kira Grace</Link>
                <Link className={styles.ReadMore} to="/kiragrace">
                  See More
                </Link>
              </div>
              <div>
                <Link to="/mossynissan">Mossy Nissan</Link>
                <Link className={styles.ReadMore} to="/mossynissan">
                  See More
                </Link>
              </div>
              <div>
                <Link to="/nativ">Nativ</Link>
                <Link className={styles.ReadMore} to="/nativ">
                  See More
                </Link>
              </div>
              <div>
                <Link to="/kompany39">Kompany 39</Link>
                <Link className={styles.ReadMore} to="/kompany39">
                  See More
                </Link>
              </div>
              <div>
                <Link to="/dalia">Dalia</Link>
                <Link className={styles.ReadMore} to="/dalia">
                  See More
                </Link>
              </div>
              <div id="last-card">
                <Link to="/frontier">Frontier Investment</Link>
                <Link className={styles.ReadMore} to="/frontier">
                  See More
                </Link>
              </div>
            </div>
            <div className={styles.ActionButton}>
              <a
                onClick={() => (window.location.hash = "contact")}
                className={styles.NextPageButton}
              >
                Begin your journey
              </a>
            </div>
          </div>
          <div className={styles.MobileInfo}>
            <h3>Our Work</h3>
            <p>
              Our clients continue to serve as our inspiration. By diving into
              the essence of each brand, we create a space for it to emerge into
              its own form, serving the world as it always was intended to.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
